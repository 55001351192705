<template>
  <div id="goods">
    <div class="goods_page_nav">
      <div class="goods_nav_in wrap clearfix">
        <ul>
          <router-link
            :class="['fl', $route.name == 'GoodsDetail' ? 'red' : '']"
            tag="li"
            to="/goods/goodsList"
            >所有礼品</router-link
          >
        </ul>
      </div>
    </div>
    <div class="mbx wrap">
      当前位置：首页 -
      <template
        v-if="$route.name == 'GoodsIndex' || $route.name == 'GoodsDetail'"
        >礼品商城</template
      >
    </div>
    <router-view></router-view>
    <div class="xian"></div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      type: 1,
    };
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#goods {
  .goods_page_nav {
    margin-top: 10px;
    border-bottom: 2px solid #ea242f;
    .goods_nav_in {
      .right_text {
        line-height: 40px;
        color: #ea242f;
        &:hover {
          cursor: pointer;
          color: #666;
        }
      }
      ul {
        li {
          line-height: 40px;
          width: 150px;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
        .router-link-exact-active {
          background: #ea242f;
          color: #fff;
          border-radius: 5px 5px 0px 0px;
        }
        .red {
          background: #ea242f;
          color: #fff;
        }
      }
    }
  }
  .mbx {
    line-height: 60px;
    font-size: 14px;
    color: #666;
  }
  .xian {
    height: 5px;
    background: #eee;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
